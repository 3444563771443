



















import CategoryPropertyList from '@/components/CategoryPropertyList.vue'
import { PropertyCategoryId } from '@/services/modules/property'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ForSale',

  components: {
    CategoryPropertyList,
  },

  setup() {
    return {
      categoryId: PropertyCategoryId.ForSale,
    }
  },
})
